'format es6';
'use strict';

import $ from 'jquery';
//import inobounce from '../vendor/inobounce.min.js';
import Accordeons from './Accordeons';
import VideoPlayers from './VideoPlayers';
import ShareSnippet from './ShareSnippet';
import { docReady, scrollToElem, isMobile } from './Utils';

const $window = $(window);

function isInViewport(el) {
	const $el = $(el);
	const st = $window.scrollTop();
	const limitTop = $el.offset().top;
	const limitBottom = limitTop + $el.height();
	const wh = $window.height();

	return (st + wh >= limitTop) && (st <= limitBottom); 
}

function handleVisibility(parentEl, videoEl) {
	if (!isInViewport(parentEl)) {
		videoEl.hide();
	} else if (!videoEl.is(':visible') && isInViewport(parentEl)) {
		videoEl.show();
	}
}

docReady.then(() => {
	Accordeons.init();
	VideoPlayers.init();


	const hamburger = $('.hamburger > a');
	hamburger.on('click', (e) => {
		e.preventDefault();

		if (isMobile()) {
			hamburger.parent().toggleClass('active');
		}
	});

	const header = $('[data-header-video]');
	const footer = $('[data-footer-video]');
	const headerVideo = header.find('.bg');
	const footerVideo = footer.find('.bg');
	footerVideo.hide();

	let spaceBetween = footer.offset().top - header.offset().top;
	$window.on('resize', () => {
		spaceBetween = footer.offset().top - header.offset().top;
	});

	$window.on('scroll.servitech', () => {
		if ($window.scrollTop() > spaceBetween / 2) {
			headerVideo.hide();
			footerVideo.show();
		} else {
			headerVideo.show();
			footerVideo.hide();
		}
		//handleVisibility(header, headerVideo);
		//handleVisibility(footer, footerVideo);
	}).scroll();


	const $body = $('body');
	const steps = $('[data-step]');
	const pannels = $('[data-pannel]');
	const pannelBackBtns = $('[data-back-btn]', pannels);

	steps.on('click.servitech', (e) => {
		e.preventDefault();
		pannels.filter(`[data-step-id="${$(e.currentTarget).data('step-id')}"]`).addClass('active');
		$body.addClass('fixed');
	});

	pannelBackBtns.on('click.servitech', (e) => {
		e.preventDefault();
		pannels.has($(e.currentTarget)).removeClass('active');
		$body.removeClass('fixed');
	});


	const scrolltoBtns = $('[data-scrollto]');
	scrolltoBtns.on('click.servitech', (e) => {
		e.preventDefault();

		const clicked = $(e.currentTarget);
		scrollToElem($(clicked.attr('href')), isMobile() ? -$('.main-nav').height() : 0);
	});


	const parallaxEls = $('[data-parallax]');
	let lastScrollValue = $window.scrollTop();
	$window.on('scroll', () => {
		const d = lastScrollValue - $window.scrollTop();
		
		parallaxEls.each((i, el) => {
			const $el = $(el);

			if (isInViewport($el)) {
				const speed = $el.data('speed');
				const lastTransform = parseInt($el.css('transform').split(',')[5]);
				const t = lastTransform + (d * speed); 
				$el.css('transform', `translate(0, ${t}px)`);
			} 
		});

		lastScrollValue = $window.scrollTop();
	});
});
