'format es6';
'use strict';

import $ from 'jquery';

let videoOverlay;
let player;
let close;
let SHARE_URL;
let timer;

const CLOSE_ANIMATION_TIME = 600;

function closeVideoPlayer(e) {
	e.preventDefault();

	videoOverlay.removeClass('opened');
	setTimeout(() => {
		player.empty();
	}, CLOSE_ANIMATION_TIME);
}

function openVideoPlayer(e) {
	e.preventDefault();

	const clicked = $(e.currentTarget);
	const html = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${clicked.data('video-btn')}?autoplay=1&rel=0&modestbranding=1&showinfo=0" frameborder="0" allowfullscreen></iframe>`;

	SHARE_URL = `http://youtu.be/${clicked.data('video-btn')}`;
	player.empty().append(html);
	videoOverlay.addClass('opened');
}

export default {
	init() {
		const playerBtns = $('[data-video-btn]');
		videoOverlay = $(`
			<div class="video-overlay">
				<div class="close">&times;</div>
				<div class="share">
					<div class="facebook share-button" data-facebook data-video-share>
						<i class="facebook icon-facebook"></i>
					</div>
					<div class="twitter share-button" data-twitter data-video-share>
						<i class="twitter icon-twitter"></i>
					</div>
					<div class="linkedin share-button" data-linkedin data-video-share>
						<i class="linkedin icon-linkedin"></i>
					</div>
				</div>
				<div class="player"></div>
			</div>
		`);
		player = videoOverlay.find('.player');
		close = videoOverlay.find('.close');
		
		if (playerBtns.length === 0) return;

		$('body').append(videoOverlay);

		/*const btns = $('.share-button', videoOverlay).add(close);
		videoOverlay.add(player).on('mousemove', () => {
			btns.addClass('shown');
			timer = setTimeout(() => {
				btns.removeClass('shown');
			}, 600);
		});/**/

		const facebookBtn = $('[data-facebook]', videoOverlay);
		const twitterBtn = $('[data-twitter]', videoOverlay);
		const linkedinBtn = $('[data-linkedin]', videoOverlay);

		facebookBtn.on('click', (e) => {
			e.preventDefault();
		
			const url = `https://www.facebook.com/dialog/share?app_id=378786902500145&display=popup&href=${SHARE_URL}`;
			window.open(url, 'Partager', 'width=520,height=570');
		});

		twitterBtn.on('click.servitech', (e) => {
			e.preventDefault();

			const url = `https://twitter.com/intent/tweet/?url=${SHARE_URL}`;
			window.open(url, 'Partager', 'width=560,height=250');
		});

		linkedinBtn.on('click.nxstar', (e) => {
			e.preventDefault();

			const url = `https://www.linkedin.com/cws/share?url=${SHARE_URL}`;
			window.open(url, 'Partager', 'width=520,height=570');
		});

		close.on('click.servitech.video_player', closeVideoPlayer);
		playerBtns.on('click.servitech.video_player', openVideoPlayer);
	},
};

