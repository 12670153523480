'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'Promise';
import { TweenMax, Cubic } from 'gsap';

const $window = $(window);

/**
 * Gets the body element
 *
 * @return {jQuery} body
 */
export const getBody = (() => {
	let body;
	return () => {
		body = body || $('body');
		return body;
	};
})();



/**
 *  Get a unique ID, prefixed with "uid"
 * 
 * @return {String}
 */
export const getUniqueID = (() => {
	let id = 0;
	return () => `uid${id++}`;
})();

/**
 * Wrapper for the $(document).ready() function to be a then-able
 * object.
 * 
 * @Promise {Function}
 * 
 * @return {Promise}
 */
export const docReady = (() => {
	return new Promise((resolve, reject) => {
		$(document).ready(resolve);
	});
})();

/**
 * Check if the viewport width is smaller or equal to a treshold
 *
 * @param {Number} treshold
 * 
 * @return {Boolean}
 */
export const isMobile = (treshold = 992) => $(window).width() <= treshold;

/**
 * Scrolls to position using TweenMax. If an offset is 
 * defined, scrolls that amount higher than the target element.
 *
 * @param {Number} pos
 * @param {Number} offset
 */
export const scrollTo = (pos, offset = 0, time = 1.2) => {
	const scroll = {
		y: $window.scrollTop(),
	};

	TweenMax.to(scroll, time, {
		y: pos + offset,
		ease: Cubic.easeInOut,
		onUpdate: () => {
			$window.scrollTop(scroll.y);
		},
	});
};

/**
 * Scrolls to an element.
 *
 * @param {jQuery Object | DOM Node} el
 * @param {Number} offset
 */
export const scrollToElem = (el, offset = 0) => {
	const $el = $(el);
	scrollTo($el.offset().top, offset);
};

/**
 * Takes an array as a param, shuffles it and returns it. Does not
 * create a new array.
 *
 * @param {Array} array
 * 
 * @return {Array} array
 */
export const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
};

/**
 * Takes a list of jQuery elements and returns the highest heigh of all.
 * 
 * @param {jQuery} elems List of jQuery object
 * 
 * @return {Number}
 */
export const getHighestHeight = (elems) => {
	if (elems.length === 0) return 0;
	return elems.toArray().reduce((maxh, el) => {
		el = $(el);
		return (el.height() > maxh) ? el.height() : maxh;
	}, $(elems[0]).height());
};

/**
 * Returns the value of an URL param by name.
 * 
 * @param {String} name The name of the parameter
 * @param {String} url The url in which it will look for. If ommited, will be the current location
 * 
 * @return {any} result Can return multiple values (null, '' or the value of the parameter)
 */
export const getParameterByName = (name, url = window.location.href) => {
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

window.requestAnimFrame = (() => {
	return window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		function requestAnimFrame(callback) {
			window.setTimeout(callback, 1000 / 60);
		};
})();