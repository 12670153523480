'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './Utils';

docReady.then(() => {
	$('[data-share-snippet]').each((i, snippet) => {
		snippet = $(snippet);
		const SHARE_URL = encodeURI(window.location.href);
		
		snippet.find('.icon-facebook').on('click.servitech', () => {
			const url = 'https://www.facebook.com/sharer/sharer.php';
			window.open(url, 'Partager', 'width=520,height=570');
		});

		snippet.find('.icon-linkedin').on('click.nxstar', ()=>{
			const url = `https://www.linkedin.com/cws/share?url=${SHARE_URL}`;
			window.open(url, 'Partager', 'width=520,height=570');
		});

		snippet.find('.icon-twitter').on('click.servitech', () => {
			const url = `https://twitter.com/intent/tweet/?url=${SHARE_URL}`;
			window.open(url, 'Partager', 'width=560,height=250');
		});
	});
});
