'format es6';
'use strict';

import $ from 'jquery';

function makeAccordeon(i, el) {
	const rootNode = $(el);
	const control = $('[data-accordeon-control]', rootNode);
	const content = $('[data-accordeon-content]', rootNode);

	function toggleAccordeon(e) {
		e.preventDefault();
		content.slideToggle();
		rootNode.toggleClass('opened');
	}

	control.on('click.simoneau.accordeon', toggleAccordeon);
}

export default {
	init() {
		const accordeons = $('[data-accordeon]');
		accordeons.each(makeAccordeon);
	},
};
